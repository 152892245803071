<template>
  <div class="error-container">
    <div class="error-content">
      <header class="error-header">
        <h1 class="error-code">{{ error.statusCode }}</h1>
        <h2 class="error-message">{{ error.message }}</h2>
      </header>
      <p class="error-description">{{ getErrorDescription() }}</p>
      <div class="action-buttons">
        <button class="btn btn-primary" @click="goHome">Return to Home</button>
        <button class="btn btn-secondary" @click="retryPage">Go Back</button>
      </div>
      <footer class="help-section">
        <h3 class="help-header">Need Assistance?</h3>
        <nav class="help-links">
          <a class="help-link" @click="handleButtonClicked('FAQ')">View FAQ</a>
          <a class="help-link" @click="contactSupport">Contact Support</a>
        </nav>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
  error: {
    type: Object,
    required: true
  }
});

const router = useRouter();

const goHome = () => router.push('/');
const retryPage = () => router.go(-1);
// const navigateToFAQ = () => router.push('/pages/questions-and-answers.vue');
const contactSupport = () => window.location.href = 'mailto:support@example.com';


const handleButtonClicked = (clickEvent) => {
  switch (clickEvent) {
    case "FAQ":
      router.push("questions-and-answers");
      break;
  }
};
const getErrorDescription = () => {
  const descriptions = {
    404: "We couldn't find the page you're looking for. It may have been moved or deleted.",
    500: "We're experiencing technical difficulties. Our team has been notified and is working on a solution.",
    default: "An unexpected error occurred. We apologize for the inconvenience and are working to resolve it."
  };
  return descriptions[props.error.statusCode] || descriptions.default;
};
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;
  padding: 2rem;
  background-color: #93a1af;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.error-content {
  text-align: center;
  width: 100%;
  max-width: 90%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 2px 0px #0e365d60, 0px -1px 4px -2px #0e365d60;
  padding: 3rem;
  margin: 0 auto;
}

.error-header {
  margin-bottom: 2rem;

  .error-code {
    font-size: 6rem;
    font-weight: 700;
    color: #dc3545;
    margin: 0;
    letter-spacing: 5px;
  }

  .error-message {
    font-size: 1.25rem;
    color: #0e365d;
    margin-top: 0.5rem;
  }
}

.error-description {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;

  .btn {
    width: 100%;
  }
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, all 0.2s ease-in;
  box-shadow: 0px 3px 2px 0px #0e365d60, 0px -1px 4px -2px #0e365d60;

  &-primary {
    background-color: #0e365d;
    color: white;

    &:hover {
      background-color: #0056b3;
    }
  }

  &-secondary {
    background-color: #6c757d;
    color: white;

    &:hover {
      background-color: #5a6268;
    }
  }
}

.help-section {
  margin-top: 3rem;
  text-align: center;
}

.help-header {
  color: #343a40;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.help-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.help-link {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .error-header {
    .error-code {
      font-size: 4rem;
    }
    .error-message {
      font-size: 1rem;
    }
  }

  .error-description {
    font-size: 0.875rem;
  }

  .action-buttons {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .error-content {
    max-width: 80%;
  }

  .error-header {
    .error-code {
      font-size: 5rem;
    }
    .error-message {
      font-size: 1.1rem;
    }
  }

  .error-description {
    font-size: 1rem;
  }

  .action-buttons {
    flex-direction: row;
  }
}

@media (min-width: 1024px) and (max-width: 1215px) {
  .error-content {
    max-width: 70%;
  }

  .error-header {
    .error-code {
      font-size: 6rem;
    }
    .error-message {
      font-size: 1.25rem;
    }
  }

  .error-description {
    font-size: 1.1rem;
  }

  .action-buttons {
    flex-direction: row;
    gap: 1rem;
  }
}

@media (min-width: 1216px) and (max-width: 1407px) {
  .error-content {
    max-width: 60%;
  }

  .error-header {
    .error-code {
      font-size: 7rem;
    }
    .error-message {
      font-size: 1.5rem;
    }
  }

  .error-description {
    font-size: 1.2rem;
  }
}

@media (min-width: 1408px) {
  .error-content {
    max-width: 50%;
  }

  .error-header {
    .error-code {
      font-size: 8rem;
    }
    .error-message {
      font-size: 1.75rem;
    }
  }

  .error-description {
    font-size: 1.25rem;
  }
}
</style>
