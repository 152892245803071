import revive_payload_client_PkTCjPXEy9 from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mkH1NTb0QY from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_joglfRXKCI from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hIaL8AvAgh from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gA8e5F0HvJ from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2iXL4w8Ist from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QcFvPRNteQ from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/dev/oxia-website/.nuxt/components.plugin.mjs";
import prefetch_client_UWR5yLGfKA from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_fbzo3br7764cnhze6nq5dxp2oq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_PkTCjPXEy9,
  unhead_mkH1NTb0QY,
  router_joglfRXKCI,
  payload_client_hIaL8AvAgh,
  navigation_repaint_client_gA8e5F0HvJ,
  check_outdated_build_client_2iXL4w8Ist,
  chunk_reload_client_QcFvPRNteQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UWR5yLGfKA
]